import React from "react"
import theme from "theme"
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>
          Our Services | Explore Our Services at Homeplus Centum ATV Rentals
        </title>
        <meta name={"description"} content={"Your Adventure Starts Here!"} />
        <meta
          property={"og:title"}
          content={
            "Our Services | Explore Our Services at Homeplus Centum ATV Rentals"
          }
        />
        <meta
          property={"og:description"}
          content={"Your Adventure Starts Here!"}
        />
        <meta
          property={"og:image"}
          content={"https://pro.sumswind.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://pro.sumswind.com/img/2077056.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://pro.sumswind.com/img/2077056.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://pro.sumswind.com/img/2077056.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://pro.sumswind.com/img/2077056.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://pro.sumswind.com/img/2077056.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://pro.sumswind.com/img/2077056.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://pro.sumswind.com/img/2077056.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 90px 0" quarkly-title="Product-3">
        <Override slot="SectionContent" align-items="center" />
        <Text
          margin="0px 0px 20px 0px"
          font="normal 600 56px/1.2 --fontFamily-sans"
          text-align="center"
          lg-width="70%"
          md-width="100%"
        >
          Our Services
        </Text>
        <Text
          margin="0px 0px 70px 0px"
          text-align="center"
          font="normal 300 22px/1.5 --fontFamily-sansHelvetica"
          color="#7c7f81"
          padding="0px 80px 0px 80px"
          lg-padding="0px 0 0px 0"
          lg-margin="0px 0px 50px 0px"
        >
          At Homeplus Centum ATV Rentals, we are dedicated to providing you
          with an unparalleled off-road experience. Our comprehensive range of
          services ensures that every aspect of your adventure is taken care of.
          Whether you're seeking an adrenaline rush or a tranquil journey
          through nature, we have something for everyone. Explore our services
          and see how we can make your next ATV adventure unforgettable.
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-template-rows="auto"
          grid-gap="0 35px"
          md-grid-template-columns="1fr"
          md-grid-gap="40px 0"
          margin="0px 0px 50px 0px"
          md-margin="0px 0px 50px 0px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
          >
            <Image
              src="https://pro.sumswind.com/img/4.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 25px 0px"
              height="300px"
              width="100%"
              object-fit="cover"
              sm-height="220px"
            />
            <Text
              margin="0px 0px 15px 0px"
              color="#89929c"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
            >
              ATV Rentals
            </Text>
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 24px/1.2 --fontFamily-sans"
              text-align="center"
            >
              Beginner-Friendly ATVs: Easy-to-handle models perfect for
              first-time riders.
              <br />
              <br />
              Advanced ATVs: High-power vehicles designed for experienced riders
              seeking a thrill.
              <br />
              <br />
              Family ATVs: Spacious models ideal for family adventures, ensuring
              comfort and safety for all ages.
            </Text>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
          >
            <Image
              src="https://pro.sumswind.com/img/5.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 25px 0px"
              height="300px"
              width="100%"
              object-fit="cover"
              sm-height="220px"
            />
            <Text
              margin="0px 0px 15px 0px"
              color="#89929c"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
            >
              Guided Tours
            </Text>
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 24px/1.2 --fontFamily-sans"
              text-align="center"
            >
              Nature Tours: Explore serene paths that showcase the beauty of the
              natural surroundings.
              <br />
              <br />
              Adventure Tours: Tackle challenging terrains with the guidance of
              our experienced tour leaders.
              <br />
              <br />
              Custom Tours: Tailor your journey with personalized routes based
              on your preferences and skill level.
            </Text>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
          >
            <Image
              src="https://pro.sumswind.com/img/6.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 25px 0px"
              height="300px"
              width="100%"
              object-fit="cover"
              sm-height="220px"
            />
            <Text
              margin="0px 0px 15px 0px"
              color="#89929c"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
            >
              Safety Training
            </Text>
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 24px/1.2 --fontFamily-sans"
              text-align="center"
            >
              Basic Safety Training: Learn essential safety practices and riding
              techniques for a secure experience.
              <br />
              <br />
              Advanced Training: Improve your skills with advanced techniques
              and maneuvers for more challenging rides.
              <br />
              <br />
              Refresher Courses: Quick training sessions to brush up on safety
              protocols and riding skills.
            </Text>
          </Box>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-template-rows="auto"
          grid-gap="0 35px"
          md-grid-template-columns="1fr"
          md-grid-gap="40px 0"
          margin="0px 0px 50px 0px"
          md-margin="0px 0px 50px 0px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
          >
            <Image
              src="https://pro.sumswind.com/img/7.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 25px 0px"
              height="300px"
              width="100%"
              object-fit="cover"
              sm-height="220px"
            />
            <Text
              margin="0px 0px 15px 0px"
              color="#89929c"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
            >
              Equipment Rental
            </Text>
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 24px/1.2 --fontFamily-sans"
              text-align="center"
            >
              Helmets: High-quality helmets to ensure your safety and comfort.
              <br />
              <br />
              Protective Gear: Full-body armor, gloves, and knee pads for added
              protection.
              <br />
              <br />
              Navigation Tools: GPS devices and trail maps to keep you on track
              during your journey.
            </Text>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
          >
            <Image
              src="https://pro.sumswind.com/img/8.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 25px 0px"
              height="300px"
              width="100%"
              object-fit="cover"
              sm-height="220px"
            />
            <Text
              margin="0px 0px 15px 0px"
              color="#89929c"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
            >
              Trail Maps
            </Text>
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 24px/1.2 --fontFamily-sans"
              text-align="center"
            >
              Beginner Trails: Easy routes perfect for newcomers to ATV riding.
              <br />
              <br />
              Intermediate Trails: Moderate trails for those looking to
              challenge themselves a bit more.
              <br />
              <br />
              Advanced Trails: Difficult paths for seasoned riders seeking an
              adrenaline rush.
            </Text>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
          >
            <Image
              src="https://pro.sumswind.com/img/9.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 25px 0px"
              height="300px"
              width="100%"
              object-fit="cover"
              sm-height="220px"
            />
            <Text
              margin="0px 0px 15px 0px"
              color="#89929c"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
            >
              Maintenance Services
            </Text>
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 24px/1.2 --fontFamily-sans"
              text-align="center"
            >
              Routine Maintenance: Regular check-ups and servicing to ensure
              optimal performance.
              <br />
              <br />
              Repairs: Expert repair services for any issues or damages.
              <br />
              <br />
              Upgrades: Enhance your ATV with the latest parts and accessories.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Cta />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
